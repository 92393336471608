import { Link } from "react-router-dom";

import { ReactComponent as AppstoreLogo } from "../assets/img/download/appstore.svg";
import { ReactComponent as PlaystoreLogo } from "../assets/img/download/playstore.svg";

export default function Footer(props) {
  const handleSubmit = (e) => {
    e?.preventDefault();
  };
  return (
    <div id="footer" className="container-fluid">
      <div className="container">
        <div className="panel panel-default row center-block">
          <div className="col-md-4 col-lg-4 col-xs-12">
            <h5>Subscribe to Our Newsletter</h5>
            <h4>Stay up to date with our news, ideas and updates</h4>
          </div>
          <div className="col-md-6 col-lg-6 col-md-offset-6 center-block col-xs-12 ">
            <form
              className="subscribe-form row"
              validate="true"
              onSubmit={handleSubmit}
            >
              <div className="form-group col-lg-6 col-md-6">
                <input
                  type="email"
                  id="custom_email"
                  name="custom_email"
                  className="form-control"
                  placeholder="Your email address"
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6">
                <button className="btn btn-custom btn-lg">Subscribe Now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="container links-footer">
        <div className="col-lg-12 col-md-12 col-xs-12 pl-0">
          <div className="col-lg-4 col-md-4 col-xs-12 pl-0">
            <h4>Ace PDG</h4>
            <p></p>
            <div className="col-lg-8">
              <div className="row">
                <p>
                  <span>
                    <i className="fa fa-map-marker fa-icon"></i>
                  </span>
                  Ace Military Apps LLC 312 W 2nd Street,<br></br>Unit #A2833
                  Casper, WY 82601
                </p>
              </div>

              <div className="row">
                <p>
                  <span>
                    <i className="fa fa-phone fa-icon"></i>
                  </span>
                  307-275-8776
                </p>
              </div>

              <div className="row">
                <p>
                  {" "}
                  <span>
                    <i className="fa fa-envelope-o fa-icon"></i>
                  </span>
                  acepdgapp@gmail.com
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-xs-4 pl-0">
            <h5>Follow Us</h5>
            <div className="flex flex-col followus-container">
              <a
                className="mb-2"
                href="https://www.facebook.com"
                target="_blank"
              >
                Facebook
              </a>
              <a className="mb-2" href="https://www.x.com" target="_blank">
                Twitter
              </a>
              <a className="mb-2" href="#about" target="_blank">
                Youtube
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-xs-4 useful-container">
            <h5>Useful Links</h5>
            <div className="flex flex-col followus-container">
              <a className="mb-2" href="#about">
                About Us
              </a>
              <a className="mb-2" href="#top">
                Services
              </a>
              <a className="mb-2" href="#contact">
                Contact Us
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-xs-12 mt-3 pr-0">
            <div
              className="row mb-4 pr-0"
              style={{
                justifyContent: "space-evenly",
                display: "flex",
                alignItems: "center",
                maxHeight: 32,
              }}
            >
              <a href="#" alt="">
                <AppstoreLogo width={144} />
              </a>
              <a href="#" alt="">
                <PlaystoreLogo width={144} />
              </a>
            </div>

            <p className="text-center">
              Available for iOS 8 and Android Devices From 5.5
            </p>
          </div>
        </div>
      </div>
      <div className="container self-center row mt-4 text-center">
        <div className="footer-bottom"></div>
        <div className="col-lg-8 col-md-4 row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <Link to="/terms-condition">
              <p>Terms & Condition</p>
            </Link>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6">
            <Link to="/privacy-policy">
              <p>Privacy Policy</p>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6">
          <Link to="/privacy-policy">
            <p>&copy; 2024 Ace PDG</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
